<template>
  <v-dialog v-model="audioDialog" persistent width="400">
    <v-card v-if="selectedAudio && selectedLog" color="white--text" dark>
      <v-card-text style="padding: 18px 15px">
        <v-row style="margin: 0px">
          <v-flex
            xs4
            sm4
            md4
            style="text-align: center !important; margin-top: 5px"
          >
            <div v-if="!isPlaying">
              <v-progress-circular
                :rotate="-90"
                :size="60"
                :width="5"
                :value="playbackTime"
                color="green"
                @click="audio('start')"
              >
                <div>
                  <v-icon size="20"> play_arrow </v-icon>
                </div>
              </v-progress-circular>
            </div>
            <div v-else>
              <v-progress-circular
                :rotate="-90"
                :size="60"
                :width="5"
                :value="playbackTime"
                color="green"
                @click="audio('pause')"
              >
                <div>
                  <v-icon size="20"> pause </v-icon>
                </div>
              </v-progress-circular>
            </div>
          </v-flex>
          <v-flex xs8 sm8 md8>
            <p style="margin: 0px; opacity: 0.6; font-size: 10px">
              PLAYING RECORDING
            </p>
            <h4>{{ selectedLog.name }} and {{ selectedLog.accounts.name }}</h4>
            <h5>
              {{ selectedLog.phone }}
            </h5>
            <h5>
              {{ $moment(selectedLog.calledOn).format("Do hh:mm a") }}
            </h5>
          </v-flex>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="selectedLog.hasOwnProperty('serverResponse')"
          fab
          small
          text
        >
          <v-icon
            small
            @click="download(selectedLog.serverResponse.RecordingUrl, 'exotel')"
            >cloud_download</v-icon
          >
        </v-btn>
        <v-btn
          v-else-if="selectedLog.defaultDialer == 'Telecmi'"
          fab
          small
          text
        >
          <v-icon
            small
            @click="download(selectedLog.telecmi.filename, 'telecmi')"
            >cloud_download</v-icon
          >
        </v-btn>

        <!-- <v-btn
          fab
          small
          text
          
          
        >
         <v-icon
            small
            color="white"
            @click="download(selectedLog.recording.name, 'phone')"
            >cloud_download</v-icon
          > 
          Download
        </v-btn> -->

        <v-btn
          v-else
          dark
          small
          @click="download(selectedLog.recording.name, 'phone')"
          >Download</v-btn
        >

        <!-- <v-btn small color="white">
          <v-icon class="secondary--text">cloud_download</v-icon>
        </v-btn> -->

        <!-- <v-btn fab small text icon="mdi-domain" v-else>
          <v-icon
            small
            color="white"
            @click="download(selectedLog.recording.name, 'phone')"
            v-if="selectedLog.hasOwnProperty('serverResponse')"
            >mdi-close</v-icon
          >
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn color="error" dark small @click="closeDialog">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Url } from "url";
import { mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "user",
      "dispoList",
      "fieldList",
      "teams",
      "selectedTeam",
      "selectedList",
    ]),
    companyDetails() {
      return this.$store.getters.companyDetails;
    },
  },
  data() {
    return {
      audioDialog: false,
      selectedAudio: null,
      selectedLog: null,
      isPlaying: false,
      playbackTime: 0,
      loader: false,
      telecmiIntegration: false,
    };
  },
  async created() {
    var self = this;
    this.getTelecmiDetails();
  },
  methods: {
    download(src, dialingOpt) {
      if (src != "") {
        if (dialingOpt == "exotel") {
          console.log(src);
          var a = document.createElement("a");

          a.href = src;
          a.setAttribute("download", src.toString());
          a.target = "_blank";
          a.click();
        } else if (dialingOpt == "telecmi") {
          var url = `https://rest.telecmi.com/v2/play?appid=${this.telecmiIntegration.config.appid}&secret=${this.telecmiIntegration.config.secret}&file=${src}`;

          var d = document.createElement("a");

          d.href = url;
          d.setAttribute("download", url.toString());
          d.target = "_blank";
          d.click();
        } else {
          console.log(src);
          var au = document.createElement("a");

          au.href = `${this.$store.state.ENDPOINT}/storages/get/${src}?access_token=${this.$store.getters.user.token}`;
          au.setAttribute("download", src.toString());
          au.target = "_blank";
          au.click();
        }
      }
    },
    async getTelecmiDetails() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=telecmi`
      );

      if (resp.body.length > 0) this.telecmiIntegration = resp.body[0];
    },
    openDialog(item, dialingOpt) {
      console.log("Open Dialog", item, dialingOpt);
      if (dialingOpt == "exotel") {
        (this.selectedAudio = null), (this.selectedLog = null);
        if (typeof item.serverResponse.RecordingUrl != "undefined") {
          var audioExotel = new Audio(`${item.serverResponse.RecordingUrl}`);
          this.selectedAudio = audioExotel;

          console.log("item", item);

          this.selectedLog = item;
          this.audioDialog = true;
        }
      } else if (dialingOpt == "telecmi") {
        var url = `https://rest.telecmi.com/v2/play?appid=${this.telecmiIntegration.config.appid}&secret=${this.telecmiIntegration.config.secret}&file=${item.telecmi.filename}`;

        console.log(url);

        this.selectedAudio = new Audio(url);
        this.selectedLog = item;

        console.log(" this.selectedLog", this.selectedLog);

        this.audioDialog = true;
      } else {
        (this.selectedAudio = null), (this.selectedLog = null);
        if (typeof item.recording != "undefined") {
          var audioPhone = new Audio(
            `${this.$store.state.ENDPOINT}/storages/get/${item.recording.name}?access_token=${this.$store.getters.user.token}`
          );
          this.selectedAudio = audioPhone;
          this.selectedLog = item;
          this.audioDialog = true;
        }
      }
    },

    closeDialog() {
      this.selectedAudio.pause();
      // reset item var
      (this.isPlaying = false),
        (this.playbackTime = 0),
        (this.selectedAudio = null),
        (this.selectedLog = null),
        (this.audioDialog = false);
    },

    audio(what) {
      const self = this;
      var audio = self.selectedAudio;
      if (typeof audio != "undefined") {
        switch (what) {
          case "start":
            self.isPlaying = true;
            audio.play();

            break;

          case "pause":
            // access audio from ongoing selected track
            audio.pause();
            // reset item var
            self.isPlaying = false;
            // self.playbackTime = 0;
            break;
        }

        audio.ontimeupdate = function(e) {
          self.playbackTime = (audio.currentTime / audio.duration) * 100;
          if (self.playbackTime >= 100 || isNaN(self.playbackTime)) {
            self.isPlaying = false;
            self.playbackTime = 0;
          }
        };
      }
    },
  },
};
</script>
<style>
.v-input__slot:before {
  border-color: rgb(0 0 0 / 2%) !important;
}
</style>
